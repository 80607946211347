import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="autofill"
export default class extends Controller {

  autofill(event) {
    const selected_option = event.target.selectedOptions[0];

    if (selected_option) {
      const keys = Object.keys(selected_option.dataset)
      for (let i = 0; i < keys.length; i++) {
        const element = document.getElementById(keys[i])
        if (element) {
          const value = selected_option.dataset[keys[i]]
          if (element.type === 'checkbox') {
            element.checked = value === 'false' ? false : value
          } else {
            element.value = value
          }

          if (event.params.triggerChange) {
            const changeEvent = new Event('change', { bubbles: true });
            element.dispatchEvent(changeEvent);
          }
        }
      }
    }
  }
}
