import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="html-helper"
export default class extends Controller {

  updateHtml({ params: { elements } }) {
    this.doUpdateHtmlBasedOnValue(elements, true)
  }

  updateHtmlBasedOnValue({target, params: { elements }}) {
    const value = target.type === 'checkbox' ? target.checked : target.value
    this.doUpdateHtmlBasedOnValue(elements, value)
  }

  doUpdateHtmlBasedOnValue(elements, current_value) {
    if (!Array.isArray(elements)) { return }

    elements.forEach(({id, value, comparator, action, inequality}) => {
      const isEqual = this.isEqual(value, current_value, comparator, inequality)

      if (action === 'disable') {
        document.getElementById(id).disabled = isEqual ? 'disabled' : null
      } else if (action === 'require') {
        document.getElementById(id).required = isEqual ? true : null
      } else if (action === 'readonly') {
        document.getElementById(id).readOnly = isEqual ? true : null
      } else {
        isEqual ? document.getElementById(id).classList.remove('d-none') : document.getElementById(id).classList.add('d-none')
      }

    })
  }

  isEqual(value, current_value, comparator, inequality) {
    if (comparator === 'gt') {
      const result = parseFloat(current_value) > parseFloat(value)
      return inequality ? !result : result
    } else if (comparator === 'gte') {
      const result = parseFloat(current_value) >= parseFloat(value)
      return inequality ? !result : result
    } else {
      const result = Array.isArray(value) ? value.indexOf(current_value) > -1 : value === current_value
      return inequality ? !result : result
    }
  }
}
